import {
  Box,
  Text,
  Heading,
  Divider,
  Flex,
  Avatar,
  Stack,
  Badge,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
} from "@chakra-ui/react";
import React from "react";

const AdminInfo = JSON.parse(sessionStorage.getItem("AdminInfo"));

const Profile = () => {
  if (!AdminInfo) {
    return <Text color="red.500">No user data found.</Text>;
  }

  return (
    <Box maxWidth="800px" mx="auto" p={4}>
      <Heading as="h2" size="lg" mb={4}>
        User Profile
      </Heading>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td background="green" color="white">
              Profile
            </Td>
            <Td background="blue" color="white">
              <Center>
                {" "}
                <Avatar src="https://bit.ly/broken-link" />
              </Center>
            </Td>
          </Tr>
          <Tr>
            <Td background="blue" color="white">
              Email
            </Td>
            <Td background="green" color="white">
              {AdminInfo.admin.email}
            </Td>
          </Tr>
          <Tr>
            <Td background="blue" color="white">
              Admin Name
            </Td>
            <Td background="green" color="white">
              {AdminInfo.admin.name}
            </Td>
          </Tr>
          <Tr>
            <Td background="blue" color="white">
              Mobile
            </Td>
            <Td background="green" color="white">
              {AdminInfo.admin.mobile}
            </Td>
          </Tr>
          <Tr>
            <Td background="blue" color="white">
              Role
            </Td>
            <Td background="green" color="white">
              {AdminInfo.admin.role}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default Profile;
