import {
  Box,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import logo from "./../Assets/logo2.png";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(sessionStorage.getItem("AdminInfo") !== null);
  }, []);

  const logoutHandler = () => {
    sessionStorage.removeItem("AdminInfo");
    sessionStorage.removeItem("token");
    setIsLoggedIn(false);
    window.location.reload();
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-body-black"
        style={{ background: "#eaeaea" }}
      >
        <div className="container-fluid">
          <span className="navbar-brand">
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                style={{ width: "80px", marginLeft: "30px" }}
              />
            </Link>
          </span>

          <form className="d-flex" role="search">
            <span className="nav-item">
              <span className="nav-link">
                {isLoggedIn ? (
                  <>
                    {" "}
                    <Button
                      marginRight={2}
                      colorScheme="blue"
                      onClick={logoutHandler}
                    >
                      Logout
                    </Button>
                  </>
                ) : (
                  <>
                    <Link to="/login">
                      <Button marginRight={2} colorScheme="blue">
                        Login
                      </Button>
                    </Link>
                  </>
                )}
              </span>
            </span>
          </form>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
