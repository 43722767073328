import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import bookImage from "./../Assets/book.jpg";
import "./Auth.css";
import * as mod from "./../../url";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleClick = () => setShow(!show);
  const submitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${mod.api_url}/api/auth/admin/login/admin`,
        {
          email,
          password,
        }
      );

      // Check if the response contains the expected data
      if (data && data.token && data.admin) {
        toast({
          title: "Login successful!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        sessionStorage.setItem("AdminInfo", JSON.stringify(data));

        navigate("/");
        window.location.reload();

        setEmail("");
        setPassword("");
      } else {
        throw new Error("Invalid response data");
      }
    } catch (error) {
      console.error("Error occurred:", error); // Debugging line
      toast({
        title: "Error occurred.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bookImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <Container width="300px" border="1px solid #e7e7e7" padding="10px">
          <Container
            border="1px solid #e7e7e7"
            padding="10px"
            borderRadius="30px"
          >
            LMS ADMIN PORTAl
          </Container>
          <Text fontSize="xx-large" fontWeight="700">
            Admin Login
          </Text>
          <VStack spacing="5px" color="black">
            <FormControl id="email" isRequired>
              <FormLabel>Enter Email </FormLabel>
              <Input
                placeholder="Enter Your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Enter Password</FormLabel>
              <InputGroup>
                <Input
                  type={show ? "text" : "password"}
                  placeholder="Enter  Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Button
              colorScheme="blue"
              width="100%"
              style={{ marginTop: 15 }}
              onClick={submitHandler}
              // isLoading={loading}
            >
              {loading ? "Logging in..." : "Login"}{" "}
            </Button>
          </VStack>
          <Box marginTop={2}>
            {" "}
            <Button colorScheme="red" width="100%" as={Link} to="/forget">
              Forget ?
            </Button>
          </Box>
        </Container>
      </Flex>
    </>
  );
};

export default Login;
